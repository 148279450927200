
import './style.css'


import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import Stats from 'stats-js/src/Stats'
import { MeshBVH, MeshBVHVisualizer } from 'three-mesh-bvh'
import nipplejs from 'nipplejs'
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js"
import firefliesVertexShader from './shaders/fireflies/vertex.js'
import firefliesFragmentShader from './shaders/fireflies/fragment.js'
import portalVertexShader from './shaders/portal/vertex.js'
import portalFragmentShader from './shaders/portal/fragment.js'
import { CompressedPixelFormat } from 'three'

 
 

/**

* Base

*/

const params =

 {
    firstPerson: false,
    helpers: false,
    displayCollider: false,
    displayBVH: false,
    visualizeDepth: 10,
    //gravity: - 30,
    gravity: 0,
    playerSpeed: 1500,
    physicsSteps: 5,
    jump: 10,
    reset: reset,
}

// Debug
const gui = new dat.GUI()

 

//GLTF File Loader

const gltfLoader = new GLTFLoader()
    .setDRACOLoader(new DRACOLoader().setDecoderPath('./wasm/'))
    .setKTX2Loader(new KTX2Loader().setTranscoderPath('./wasm/'))
    .setMeshoptDecoder(MeshoptDecoder)

 

let horizonAxis = 0
let verticalAxis = 0
let stats
let blocked = false
let mixer = { update: () => { } }

 
//Person Animation Action
let idleAction = { play: () => { }, stop: () => { } }
let walkAction = { play: () => { }, stop: () => { } }
let runAction = { play: () => { }, stop: () => { } }
let jumpAction = { play: () => { }, stop: () => { } }


 

// Person Animation Control

const proxy = new Proxy({ speed: 0 },

    {

        set(obj, prop, newval) {

 

 

            if (newval >= 0.1 && newval <= 0.5) {

 

                idleAction.stop()

                runAction.stop()

 

                walkAction.play()

 

            } else if (newval > 0.5) {

 

                idleAction.stop();

                walkAction.stop();

                runAction.play()

            } else if (newval < 0.1) {

                walkAction.stop()

                runAction.stop()

            }

 

            obj[prop] = newval

 

            return true

        }

   }

)

 

//Assign Some Variables

let renderer, camera, controls

let objectsToTest  = []

let collider, visualizer, player

let playerIsOnGround = false

let fwdPressed = false, bkdPressed = false, lftPressed = false, rgtPressed = false

let playerVelocity = new THREE.Vector3()

let upVector = new THREE.Vector3(0, 1, 0)

let tempVector = new THREE.Vector3()

let tempVector2 = new THREE.Vector3()

let tempBox = new THREE.Box3()

let tempMat = new THREE.Matrix4()

let tempSegment = new THREE.Line3()

 

let tempVectorNormalized = new THREE.Vector3()

let tempVectorWalkSpeed = new THREE.Vector3()

 
let collisionObject = {}

let bulletProofCircle, projectorProjector1, projectorProjector2
 

/**
* Scene
*This is where you place objects, lights and cameras.
*/

const scene = new THREE.Scene()
const envLoader = new THREE.CubeTextureLoader()
const texture = envLoader.load([
    'textures/environmentMaps/physics/environmentMaps/5/px.jpg',
    'textures/environmentMaps/physics/environmentMaps/5/nx.jpg',
    'textures/environmentMaps/physics/environmentMaps/5/py.jpg',
    'textures/environmentMaps/physics/environmentMaps/5/ny.jpg',
    'textures/environmentMaps/physics/environmentMaps/5/pz.jpg',
    'textures/environmentMaps/physics/environmentMaps/5/nz.jpg',
]);
scene.background = texture
//scene.fog = new THREE.Fog( scene.background, 1, 5000 )

 

/**
* Clock
* Object for keeping track of time. This uses performance.now if it is available, otherwise it reverts to the less accurate Date.now
*/

const clock = new THREE.Clock()
let oldElapsedTime = 0

/**
* Raycaster
* You can use that technique to detect if there is a wall in front of the player, test if the laser gun hit something, test if something is currently under the mouse to simulate mouse events, and many other things.
*/

let raycaster = new THREE.Raycaster()
raycaster.far = 1500

 

const rayOrigin = new THREE.Vector3(-3, 0, 0)
const rayDirection = new THREE.Vector3(1, 0, 0)
rayDirection.normalize()
raycaster.set(rayOrigin, rayDirection)

 

/**
 * direction vector
 * An 3D arrow object for visualizing directions
 */

  const dir = new THREE.Vector3(1, 2, 0)
  //normalize the direction vector (convert to vector of length 1)
  dir.normalize()

  const origin = new THREE.Vector3(0, 0, 0)
  const length = 1
  const hex = 0xffff00

  const arrowHelper = new THREE.ArrowHelper(dir, origin, length, hex)
  arrowHelper.visible = false

  scene.add(arrowHelper)

 

 

function init() {

    const bgColor = 0x263238 / 2

 

    /**
     * Canvas
     */

    const canvas = document.querySelector('canvas.webgl')

    /**
     * The WebGL renderer displays your beautifully crafted scenes using WebGL
     * renderer setup
     */

    renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvas })
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(bgColor, 1)
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.outputEncoding = THREE.sRGBEncoding

    /**
     * Light Setup
     */
    /**
     * HemisphereLight
     * A light source positioned directly above the scene, with color fading from the sky color to the ground color
     */
    //  const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.6 )
    //  hemiLight.color.setHSL( 0.6, 1, 0.6 )
    //  hemiLight.groundColor.setHSL( 0.095, 1, 0.75 )
    //  hemiLight.position.set( 0, 50, 0 )
    //  scene.add( hemiLight )

    //  const hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 1200 )
    //  scene.add( hemiLightHelper )

    /**
     * This light globally illuminates all objects in the scene equally.
     */

    const ambient = new THREE.AmbientLight( 0xaaaaaa )
    scene.add( ambient )


     /**
     * DirectionalLight
     * A light that gets emitted in a specific direction. This light will behave as though it is infinitely far away and the rays produced from it are all parallel.
     */
    const light = new THREE.DirectionalLight(0xaaaaaa)
    light.position.set(30, 100, 40)
    light.castShadow = true

    const shadowCam = light.shadow.camera
    shadowCam.bottom = shadowCam.left = - 500
    shadowCam.top = 500
    shadowCam.right = 500
    shadowCam.near = 1
    shadowCam.far = 500

    scene.add(light)


    const DirectionalLightHelper = new THREE.DirectionalLightHelper( light, 1200 )
    //scene.add( DirectionalLightHelper )

 

    /**
     * PerspectiveCamera Camera Setup
     * This projection mode is designed to mimic the way the human eye sees. It is the most common projection mode used for rendering a 3D scene.
     */  

    camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 10, 99999)
    camera.position.set(-125, 600, 6800)
    camera.updateProjectionMatrix()
    window.camera = camera

 

    /**

     * Control Setup

     */

    controls = new OrbitControls(camera, renderer.domElement)

    controls.enableDamping = true
    controls.maxPolarAngle = Math.PI * 0.5
    controls.minPolarAngle = Math.PI * 0.495

   

    /**

     * Stats Setup

     */

    stats = new Stats()
    document.body.appendChild(stats.dom)

   

    /**
     * Add Plyer inside environment
     */
     loadThings()

     /**
     * Add Plyer capsule
     */
    player = new THREE.Mesh(
        new RoundedBoxGeometry(1.0, 2.0, 1.0, 10, 0.5),
        new THREE.MeshStandardMaterial({
            wireframe: true,
            transparent: true,
            opacity: 0,
            alphaTest: 0
        })
    )

    player.geometry.translate(0, - 0.5, 0)
    player.capsuleInfo = {
        radius: 0.5,
        segment: new THREE.Line3(new THREE.Vector3(), new THREE.Vector3(0, - 1.0, 0.0))
    }
    player.scale.set(1000,1000,1000)
    player.castShadow = true
    player.receiveShadow = true
    player.material.shadowSide = 2

    scene.add(player)
    reset()

 

    // dat.gui

 

    gui.add(params, 'firstPerson').onChange(v => {
        if (!v) {
            player.visible = true
            camera
                .position
                .sub(controls.target)
                .normalize()
                .multiplyScalar(10)
                .add(controls.target)
            controls.maxPolarAngle = Math.PI / 2
            controls.minDistance = 1
            controls.maxDistance = 20
        } else {
            player.visible = false;
            controls.maxPolarAngle = Math.PI
            controls.minDistance = 1e-4
            controls.maxDistance = 1e-4
        }
    })

    // gui.add(params, 'helpers').onChange(v => {
    //     arrowHelper.visible = v
    // })

 

    // const visFolder = gui.addFolder('Visualization')
    // visFolder.add(params, 'displayCollider').onChange(v => {
    //     collider.visible = v
    // })

    // visFolder.add(params, 'displayBVH').onChange(v => {
    //     visualizer.visible = v
    // })

    // visFolder.add(params, 'visualizeDepth', 1, 20, 1).onChange(v => {
    //     visualizer.depth = v
    //     visualizer.update()
    // })

    // visFolder.open()

 

    // const physicsFolder = gui.addFolder('Player')
    // physicsFolder.add(params, 'physicsSteps', 0, 30, 1)
    // physicsFolder.add(params, 'gravity', - 100, 100, 0.01).onChange(v => {
    //     params.gravity = parseFloat(v)
    // })
    // physicsFolder.add(params, 'playerSpeed', 1, 20)
    // physicsFolder.add(params, 'jump', 1, 30)
    // physicsFolder.open()
 

    gui.add(params, 'reset')
    // gui.open()
    gui.close()
 

    window.addEventListener('keydown', function (e) {

        switch (e.code) {

            case 'KeyW': fwdPressed = true; break;

            case 'KeyS': bkdPressed = true; break;

            case 'KeyD': rgtPressed = true; break;

            case 'KeyA': lftPressed = true; break;

            case 'Space':              

                jump();
                break;

            case 'KeyZ':

                interact();
                break;

            default:

 

        }

 

    });

 

    window.addEventListener('keyup', function (e) {

 

        switch (e.code) {

 

            case 'KeyW': fwdPressed = false; break;

            case 'KeyS': bkdPressed = false; break;

            case 'KeyD': rgtPressed = false; break;

            case 'KeyA': lftPressed = false; break;

            default:

 

 

        }

 

    });

 

 

}

//INIT FUNCTION END

 
// function shootRay() {
//     raycaster.set(player.position, tempVectorNormalized)
//     const intersects = raycaster.intersectObjects(objectsToTest)
// }
function shootRay() {
   
    //cast left
    dir.set(-1,0,0)
    dir.normalize()
    raycaster.set(player.position, tempVectorNormalized)

    let intersects = raycaster.intersectObjects(objectsToTest)
    if (intersects.length > 0) {
        blocked = true  
    }

    //cast right
    dir.set(1,0,0)
    dir.normalize()
    raycaster.set(player.position, tempVectorNormalized)

    intersects = raycaster.intersectObjects(objectsToTest)
    if (intersects.length > 0) {
        blocked = true  
    }

    //cast down
    dir.set(0,-1,0)
    dir.normalize()
    raycaster.set(player.position, tempVectorNormalized)

    intersects = raycaster.intersectObjects(objectsToTest)
    if (intersects.length > 0) {
        blocked = true  
    }

    // CHECK COLLISION SET MATERIAL COLOR RED
    //console.log(objectsToTest)
    for(let object of objectsToTest){
        //    const color1 = new THREE.Color( 0xff0000 )
        //    object.material.color = color1
        //    object.material.needsUpdate = true
   
   
   
        //object.material.color.set('#ff0000')
    }
    // AFTER COLLISION SET MATERIAL COLOR BLUE
    for(let intersect of intersects){
        // const color2 = new THREE.Color( 0x0000ff )
        // intersect.material.color = color2
        // intersect.material.needsUpdate = true
       
       
        //intersect.object.material.color.set('#0000ff')
    }

    if (intersects.length > 0) {

        //blocked = true

    }

    if (intersects.length == 0) {
        blocked = false
    }


    //ON-OFF VIDEO SOUNDS

    //STEP 1: On-Off Center Video
    let soundVideoCenter = raycaster.intersectObject(bulletProofCircle)
    if (typeof soundVideoCenter !== 'undefined' && soundVideoCenter.length > 0) {
            var video = document.getElementById("videoCenter")
            if(video.muted){
                video.muted = !video.muted
                var videol=document.getElementById("videoLeft")
                if(!videol.muted){
                    videol.muted = !videol.muted;
                }
                var videor=document.getElementById("videoRight")
                if(!videor.muted){
                    videor.muted = !videor.muted;
                }
            }
    }else if(typeof soundVideoCenter !== 'undefined' && soundVideoCenter.length <= 0){
        var video = document.getElementById("videoCenter")
        video.muted = true
    }

    //STEP 2: On-Off Left Video
    let soundVideoLeft = raycaster.intersectObject(projectorProjector1)
    if (typeof soundVideoLeft !== 'undefined' && soundVideoLeft.length > 0) {
            var video = document.getElementById("videoLeft")
            if(video.muted){
                video.muted = !video.muted
                var videol=document.getElementById("videoRight")
                if(!videol.muted){
                    videol.muted = !videol.muted;
                }
                var videor=document.getElementById("videoCenter")
                if(!videor.muted){
                    videor.muted = !videor.muted;
                }
            }
    }else if(typeof soundVideoLeft !== 'undefined' && soundVideoLeft.length <= 0){
        var video = document.getElementById("videoLeft")
        video.muted = true
    }


    //STEP 3: On-Off Right Video
    let soundVideoRight = raycaster.intersectObject(projectorProjector2)
    if (typeof soundVideoRight !== 'undefined' && soundVideoRight.length > 0) {
            var video = document.getElementById("videoRight")
            if(video.muted){
                video.muted = !video.muted
                var videol=document.getElementById("videoLeft")
                if(!videol.muted){
                    videol.muted = !videol.muted;
                }
                var videor=document.getElementById("videoCenter")
                if(!videor.muted){
                    videor.muted = !videor.muted;
                }
            }
    }else if(typeof soundVideoRight !== 'undefined' && soundVideoRight.length <= 0){
        var video = document.getElementById("videoRight")
        video.muted = true
    }

}

 

 

function interact() {

   
    raycaster.set(player.position, tempVectorNormalized)

    const intersects = raycaster.intersectObjects(objectsToTest)

    //console.log(objectsToTest)

    if (intersects.length > 0) {      

        intersects[0].object.interact()

    }

 

    arrowHelper.position.copy(player.position)

    arrowHelper.setDirection(tempVectorNormalized)

 

}

 

function loadThings() {

 

    /**

     * Load Player / Person / Character

     */

    gltfLoader.load("models/ajayshanker.glb", (gltf) => {

       

        const scene = gltf.scene || gltf.scenes[0]

        //scene.rotation.y = - Math.PI

        scene.position.y = -1.5
     

 

        scene.castShadow = true

        scene.receiveShadow = true

     

 

        // updateAllMaterials(scene)

 

        player.add(scene)

 

 

 

        const animations = gltf.animations

        //console.log(animations)

        mixer = new THREE.AnimationMixer(player)

        idleAction = mixer.clipAction(animations[0])

        walkAction = mixer.clipAction(animations[4])

        runAction = mixer.clipAction(animations[3])

        jumpAction = mixer.clipAction(animations[1])

        jumpAction.setLoop(THREE.LoopOnce)

        jumpAction.clampWhenFinished = true

        jumpAction.enable = true

        idleAction.play()

 

 

        // saveState();

 

        window.setInterval(shootRay, 100)

 

    })

 

    /**

     * Load Enviroment Structure

     */
     const fbxLoader = new FBXLoader()

     fbxLoader.load('models/town.fbx', (object) => {

            const fbxScene = object
            fbxScene.scale.setScalar(5)
            fbxScene.position.y = 1595
            fbxScene.traverse( function ( child ) {
                    if ( child.isMesh ) {
                        if (child.name.startsWith("Mesh") || child.name.startsWith("Icosphere") || child.name.startsWith("Line") || child.name.startsWith("Box") || child.name.startsWith("Cylinder") || child.name.startsWith("Plane"))
                        {
                            objectsToTest.push(child)
                            let geo = child.geometry
                            geo.boundsTree = new MeshBVH(geo, { lazyGeneration: true })
                            collider = new THREE.Mesh(geo)
                            collider.material.wireframe = true
                            collider.material.opacity = 0
                            collider.material.transparent = true
                            visualizer = new MeshBVHVisualizer(collider, params.visualizeDepth)
                            collider.visible = false
                            visualizer.visible = false
                            // collider.scale.setScalar(5)
                            // visualizer.scale.setScalar(5)
                            // collider.rotation.x =   -3 * Math.PI/2
                            // visualizer.rotation.x = - 3 * Math.PI/2
                            scene.add(visualizer)
                            scene.add(collider)
                           
                            child.material.visible = true
                        }else{                        
                            child.castShadow = true
                            child.receiveShadow = true
                        }
                       
                    }
                })            
            scene.add(fbxScene)  

        })

        //GREAT WALL OF CHINA

        //STEP 1: PROTECT STAGE
        let radius = 7
        let widthSegments = 8
        let heightSegments = 8
        //let sphereMaterial = new THREE.MeshBasicMaterial( { color: 0xffff00 } )
        let sphereMaterial = new THREE.MeshBasicMaterial( { color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1 } )
        let sphereGeometry = new THREE.SphereGeometry( radius, widthSegments , heightSegments )
        bulletProofCircle = new THREE.Mesh( sphereGeometry, sphereMaterial )
        bulletProofCircle.name = 'bulletGlass'
        bulletProofCircle.position.y = 200
        bulletProofCircle.position.z = 200
        bulletProofCircle.scale.setScalar(500)
        objectsToTest.push(bulletProofCircle)
        scene.add( bulletProofCircle )

        //STEP 2.1: PROTECT CHAIR - RIGHT SIDE
        let boxGeometry1 = new THREE.ConeGeometry( 5, 20, 32 )
        //let boxMaterial1 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
        let boxMaterial1 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
        let bulletChair1 = new THREE.Mesh( boxGeometry1, boxMaterial1 )
        bulletChair1.name = 'bulletChair1'
        bulletChair1.position.y = -800
        bulletChair1.position.z = 4300
        bulletChair1.position.x = 5100
        bulletChair1.scale.setScalar(300)
        objectsToTest.push(bulletChair1)      
        scene.add( bulletChair1 )

        //STEP 2.2: PROTECT CHAIR
        let boxGeometry2 = new THREE.ConeGeometry( 5, 20, 32 )
        //let boxMaterial2 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
        let boxMaterial2 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
        let bulletChair2 = new THREE.Mesh( boxGeometry2, boxMaterial2 )
        bulletChair2.name = 'bulletChair2'
        bulletChair2.position.y = -800
        bulletChair2.position.z = -3800
        bulletChair2.position.x = -5100
        bulletChair2.scale.setScalar(310)
        objectsToTest.push(bulletChair2)      
        scene.add( bulletChair2 )


        //STEP 2.3: PROTECT CHAIR
        let boxGeometry3 = new THREE.ConeGeometry( 5, 20, 32 )
        //let boxMaterial3 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
        let boxMaterial3 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
        let bulletChair3 = new THREE.Mesh( boxGeometry3, boxMaterial3 )
        bulletChair3.name = 'bulletChair3'
        bulletChair3.position.y = -800
        bulletChair3.position.z = 4300
        bulletChair3.position.x = -5100
        bulletChair3.scale.setScalar(300)
        objectsToTest.push(bulletChair3)      
        scene.add( bulletChair3 )


        //STEP 2.4: PROTECT CHAIR - RIGHT SIDE
        let boxGeometry4 = new THREE.ConeGeometry( 5, 20, 32 )
        //let boxMaterial4 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
        let boxMaterial4 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
        let bulletChair4 = new THREE.Mesh( boxGeometry4, boxMaterial4 )
        bulletChair4.name = 'bulletChair4'
        bulletChair4.position.y = -800
        bulletChair4.position.z = -3750
        bulletChair4.position.x = 5200
        bulletChair4.scale.setScalar(340)
        objectsToTest.push(bulletChair4)      
        scene.add( bulletChair4 )



         //STEP 3.1: PROTECT TREE
         let treeGeometry1 = new THREE.ConeGeometry( 5, 20, 32 )
         //let treeMaterial1 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
         let treeMaterial1 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
         let bulletTree1 = new THREE.Mesh( treeGeometry1, treeMaterial1 )
         bulletTree1.name = 'bulletTree1'
         bulletTree1.position.y = -800
         bulletTree1.position.z = -6790
         bulletTree1.position.x = 9000
         bulletTree1.scale.setScalar(340)
         objectsToTest.push(bulletTree1)      
         scene.add( bulletTree1 )


         //STEP 3.2: PROTECT TREE
         let treeGeometry2 = new THREE.ConeGeometry( 5, 20, 32 )
         //let treeMaterial2 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
         let treeMaterial2 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
         let bulletTree2 = new THREE.Mesh( treeGeometry2, treeMaterial2 )
         bulletTree2.name = 'bulletTree2'
         bulletTree2.position.y = -800
         bulletTree2.position.z = -8900
         bulletTree2.position.x = 7000
         bulletTree2.scale.setScalar(340)
         objectsToTest.push(bulletTree2)      
         scene.add( bulletTree2 )


         //STEP 3.3: PROTECT TREE
         let treeGeometry3 = new THREE.ConeGeometry( 5, 20, 32 )
         //let treeMaterial3 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
         let treeMaterial3 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
         let bulletTree3 = new THREE.Mesh( treeGeometry3, treeMaterial3 )
         bulletTree3.name = 'bulletTree3'
         bulletTree3.position.y = -800
         bulletTree3.position.z = -6790
         bulletTree3.position.x = -9000
         bulletTree3.scale.setScalar(340)
         objectsToTest.push(bulletTree3)      
         scene.add( bulletTree3 )


         //STEP 3.4: PROTECT TREE
         let treeGeometry4 = new THREE.ConeGeometry( 5, 20, 32 )
         //let treeMaterial4 = new THREE.MeshBasicMaterial( {color: 0x00ff00} )
         let treeMaterial4 = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0, alphaTest: 1} )
         let bulletTree4 = new THREE.Mesh( treeGeometry4, treeMaterial4 )
         bulletTree4.name = 'bulletTree4'
         bulletTree4.position.y = -800
         bulletTree4.position.z = -8900
         bulletTree4.position.x = -7000
         bulletTree4.scale.setScalar(340)
         objectsToTest.push(bulletTree4)      
         scene.add( bulletTree4 )


        //STEP 4.1: PROTECT PROJECTOR DESK
        let projectorGeometry1 = new THREE.CapsuleGeometry( 5, 5, 4, 10)
        //let projectorMaterial1 = new THREE.MeshBasicMaterial( {color: 0x0B16F4} )
        let projectorMaterial1 = new THREE.MeshBasicMaterial( {color: 0x0B16F4, transparent: true, opacity: 0, alphaTest: 1} )
        projectorProjector1 = new THREE.Mesh( projectorGeometry1, projectorMaterial1 )
        projectorProjector1.name = 'projectorProjector1'
        projectorProjector1.position.y = -800
        projectorProjector1.position.z = -86
        projectorProjector1.position.x = -11000
        projectorProjector1.rotation.y =  5 * Math.PI / 2
        projectorProjector1.scale.setScalar(300)
        objectsToTest.push(projectorProjector1)  
        scene.add( projectorProjector1 )

         //STEP 4.2: PROTECT PROJECTOR DESK
         let projectorGeometry2 = new THREE.CapsuleGeometry( 5, 5, 4, 10);
         //let projectorMaterial2 = new THREE.MeshBasicMaterial( {color: 0x0B16F4} );
         let projectorMaterial2 = new THREE.MeshBasicMaterial( {color: 0x0B16F4, transparent: true, opacity: 0, alphaTest: 1} )
         projectorProjector2 = new THREE.Mesh( projectorGeometry2, projectorMaterial2 );
         projectorProjector2.name = 'projectorProjector2'
         projectorProjector2.position.y = -800
         projectorProjector2.position.z = -86
         projectorProjector2.position.x = 11000
         projectorProjector2.rotation.y =  5 * Math.PI / 2
         projectorProjector2.scale.setScalar(300)
         objectsToTest.push(projectorProjector2)  
         scene.add( projectorProjector2 )


          //STEP 4.3: PROTECT PROJECTOR DESK
          let projectorGeometry3 = new THREE.CapsuleGeometry( 5, 5, 4, 10);
          //let projectorMaterial3 = new THREE.MeshBasicMaterial( {color: 0x0B16F4} );
          let projectorMaterial3 = new THREE.MeshBasicMaterial( {color: 0x0B16F4, transparent: true, opacity: 0, alphaTest: 1} )
          let projectorProjector3 = new THREE.Mesh( projectorGeometry3, projectorMaterial3 );
          projectorProjector3.name = 'projectorProjector3'
          projectorProjector3.position.y = -800
          projectorProjector3.position.z = -11000
          projectorProjector3.position.x = 0
          projectorProjector3.rotation.y =  5 * Math.PI / 2
          projectorProjector3.scale.setScalar(300)
          objectsToTest.push(projectorProjector3)  
          scene.add( projectorProjector3 )


       
        //ADD ADVERTISEMENT INSIDE ENVOIROMENT
        //STEP 5.1 CENTER SCREEN
        let video1 = document.getElementById( 'videoCenter' )
video1.play()
let videoMirrorTexture = new THREE.VideoTexture( video1 )
videoMirrorTexture.minFilter = THREE.LinearFilter
videoMirrorTexture.magFilter = THREE.LinearFilter
videoMirrorTexture.format = THREE.RGBAFormat
videoMirrorTexture.crossOrigin = 'anonymous'
videoMirrorTexture.needsUpdate = true

let videoObjectCenter = new THREE.Mesh(
            new THREE.BoxGeometry(4000, 2400, 2000),
            new THREE.MeshBasicMaterial({ color: 0xffffff, map: videoMirrorTexture, transparent: true, side: THREE.DoubleSide  })
        )
videoObjectCenter.needsUpdate = true
videoObjectCenter.scale.set(1.3,1.3,1.3)
videoObjectCenter.position.set( 16, 530, -110 )
videoObjectCenter.name="tvCenterScreen"

        scene.add( videoObjectCenter )


        //STEP 5.2 Right SCREEN
        let videoRight = document.getElementById( 'videoRight' )
        videoRight.play()
        let textureVideoRight = new THREE.VideoTexture( videoRight )
textureVideoRight.minFilter = THREE.LinearFilter
textureVideoRight.magFilter = THREE.LinearFilter
textureVideoRight.format = THREE.RGBAFormat
textureVideoRight.crossOrigin = 'anonymous'
textureVideoRight.needsUpdate

        let videoRihtObject = new THREE.Mesh(
                new THREE.PlaneGeometry(320, 225),
                new THREE.MeshBasicMaterial({ map: textureVideoRight, side: THREE.DoubleSide })
        )
        videoRihtObject.position.y = -900
        videoRihtObject.position.x = 10999
        videoRihtObject.position.z = 120
        videoRihtObject.scale.setScalar(6)
        scene.add( videoRihtObject )


        //STEP 5.3 Left SCREEN
        let videoLeft = document.getElementById( 'videoLeft' )
        videoLeft.play()
        let textureVideoLeft = new THREE.VideoTexture( videoLeft )
textureVideoLeft.minFilter = THREE.LinearFilter
textureVideoLeft.magFilter = THREE.LinearFilter
textureVideoLeft.format = THREE.RGBAFormat
textureVideoLeft.crossOrigin = 'anonymous'
textureVideoLeft.needsUpdate

        let videoLeftObject = new THREE.Mesh(
                new THREE.PlaneGeometry(320, 225),
                new THREE.MeshBasicMaterial({ map: textureVideoLeft, side: THREE.DoubleSide })
        )
        videoLeftObject.position.y = -900
        videoLeftObject.position.x = -10999
        videoLeftObject.position.z = 120
        videoLeftObject.scale.setScalar(6)
        scene.add( videoLeftObject )
 



 

}

 

 

function updatePlayer(delta) {

 

    playerVelocity.y += playerIsOnGround ? 0 : delta * params.gravity

    player.position.addScaledVector(playerVelocity, delta)



    // move the player

    const angle = controls.getAzimuthalAngle()

   

    if (fwdPressed) {

 

        tempVector.set(0, 0, - 1).applyAxisAngle(upVector, angle)

        player.position.addScaledVector(tempVector, params.playerSpeed * delta)

 

        player.rotation.y = Math.atan2(tempVector.x, tempVector.z)

 

        // proxy.speed = tempVector.length();

 

    }

 

    if (bkdPressed) {

 

        tempVector.set(0, 0, 1).applyAxisAngle(upVector, angle)

        player.position.addScaledVector(tempVector, params.playerSpeed * delta)

 

        player.rotation.y = Math.atan2(tempVector.x, tempVector.z)

 

        // proxy.speed = tempVector.length();

    }

 

    if (lftPressed) {

 

        tempVector.set(- 1, 0, 0).applyAxisAngle(upVector, angle)

        player.position.addScaledVector(tempVector, params.playerSpeed * delta)

 

        player.rotation.y = Math.atan2(tempVector.x, tempVector.z)

 

        // proxy.speed = tempVector.length();

    }

 

    if (rgtPressed) {

 

        tempVector.set(1, 0, 0).applyAxisAngle(upVector, angle)

        player.position.addScaledVector(tempVector, params.playerSpeed * delta)

 

 

        player.rotation.y = Math.atan2(tempVector.x, tempVector.z)

 

        // proxy.speed = tempVector.length();

    }

 

    if (horizonAxis !== 0 && verticalAxis !== 0) {

 

        // tempVector.set(horizonAxis, 0, verticalAxis).applyAxisAngle(upVector, angle).normalize();

        tempVector.set(horizonAxis, 0, verticalAxis).applyAxisAngle(upVector, angle);

 

        const length = tempVector.length()
        //console.log(length)

        tempVectorNormalized.copy(tempVector).normalize()

        if (length > 0.5 && !blocked) {

            player.position.addScaledVector(tempVectorNormalized, params.playerSpeed * delta)

        } else if (length >= 0.1 && length <= 0.5 && !blocked) {

            tempVectorWalkSpeed.copy(tempVectorNormalized).multiplyScalar(0.35)

            player.position.addScaledVector(tempVectorWalkSpeed, params.playerSpeed * delta)

        }

        player.rotation.y = Math.atan2(tempVector.x, tempVector.z)

        proxy.speed = length

    }

    player.updateMatrixWorld()

 

    // adjust player position based on collisions

    const capsuleInfo = player.capsuleInfo

    tempBox.makeEmpty()

    tempMat.copy(collider.matrixWorld).invert()      

    tempSegment.copy(capsuleInfo.segment)


    // get the position of the capsule in the local space of the collider

    tempSegment.start.applyMatrix4(player.matrixWorld).applyMatrix4(tempMat)

    tempSegment.end.applyMatrix4(player.matrixWorld).applyMatrix4(tempMat)

 

    // get the axis aligned bounding box of the capsule

    tempBox.expandByPoint(tempSegment.start)

    tempBox.expandByPoint(tempSegment.end)

 

    tempBox.min.addScalar(- capsuleInfo.radius)

   tempBox.max.addScalar(capsuleInfo.radius)

    //console.log(collider.geometry.boundsTree)
    //console.log( collisionObject[0].geometry.boundsTree)

    //console.log(collisionObject[0].geometry.boundsTree)

   //collisionObject.forEach(collider => {})
    collider.geometry.boundsTree.shapecast({

 

            intersectsBounds: box => box.intersectsBox(tempBox),
   
     
   
            intersectsTriangle: tri => {
   
     
   
                // check if the triangle is intersecting the capsule and adjust the
   
                // capsule position if it is.
   
                const triPoint = tempVector;
   
                const capsulePoint = tempVector2;
   
     
   
                const distance = tri.closestPointToSegment(tempSegment, triPoint, capsulePoint)
   
                if (distance < capsuleInfo.radius) {
   
     
   
                    const depth = capsuleInfo.radius - distance;
   
                    const direction = capsulePoint.sub(triPoint).normalize();
   
     
   
                    tempSegment.start.addScaledVector(direction, depth);
   
                    tempSegment.end.addScaledVector(direction, depth);
   
     
   
                }
   
     
   
            }
   
     
   
        })
 

 

    // get the adjusted position of the capsule collider in world space after checking

    // triangle collisions and moving it. capsuleInfo.segment.start is assumed to be

    // the origin of the player model.

    const newPosition = tempVector;

    newPosition.copy(tempSegment.start).applyMatrix4(collider.matrixWorld);

 

    // check how much the collider was moved

    const deltaVector = tempVector2;

    deltaVector.subVectors(newPosition, player.position);

 

    // if the player was primarily adjusted vertically we assume it's on something we should consider ground

    playerIsOnGround = deltaVector.y > Math.abs(delta * playerVelocity.y * 0.25);

 

 

 

    const offset = Math.max(0.0, deltaVector.length() - 1e-5);

    deltaVector.normalize().multiplyScalar(offset);

 

    // adjust the player model

    player.position.add(deltaVector)

 

    if (!playerIsOnGround) {

 

        deltaVector.normalize()

        playerVelocity.addScaledVector(deltaVector, - deltaVector.dot(playerVelocity))

 

    } else {

 

        playerVelocity.set(0, 0, 0)

 

    }

 

    // adjust the camera

    camera.position.sub(controls.target)

    controls.target.copy(player.position)

    camera.position.add(player.position)

 

    // if the player has fallen too far below the level reset their position to the start

    if ( player.position.y < -25) {

 

        reset()

 

    }

 

}

 

function sceneInit() {

 

    let manager = nipplejs.create({

        restJoystick: true,

        mode: 'static',

        position: {left: '50%', bottom: '10%'},

        color: 'red'

    });

 

    manager.on("move", function (evt, { vector }) {

        const { x, y } = vector;

        // console.log(vector)

        // set({ controls: { horizonAxis: -x, verticalAxis: y } })

        horizonAxis = x;

        verticalAxis = -y;

        // runAction.play();

        // idleAction.stop();

    });

 

    manager.on("end", function (evt, data) {

 

        // set({ controls: { horizonAxis: 0, verticalAxis: 0 } })

        horizonAxis = 0;

        verticalAxis = 0;

        runAction.stop();

        walkAction.stop();

        idleAction.play();

    });

 

 

    // desktop.interact = handleOpen;

 

}

 

function tick() {

 

    stats.update();

    requestAnimationFrame(tick);

 

    // const elapsedTime = clock.getElapsedTime()

    // const delta = elapsedTime - oldElapsedTime

    // oldElapsedTime = elapsedTime

 

    // Update materials

    // portalLightMaterial.uniforms.uTime.value = oldElapsedTime

    // firefliesMaterial.uniforms.uTime.value = oldElapsedTime

   

    const delta = Math.min(clock.getDelta(), 0.1);

    oldElapsedTime += delta

 

    if (collider) {

 

        const physicsSteps = params.physicsSteps;

 

        for (let i = 0; i < physicsSteps; i++) {

 

            updatePlayer(delta / physicsSteps);

 

        }

 

    }

   

 

    mixer.update(delta);

 

    // TODO: limit the camera movement based on the collider

    // raycast in direction of camera and move it if it's further than the closest point

 

    controls.update();

 

    renderer.render(scene, camera);

}

 

function jump() {

    if (playerIsOnGround) {

 

        // playerVelocity.y = 10.0;

        idleAction.stop()

        walkAction.stop()

        runAction.stop()

        // jumpAction.play().reset();

        jumpAction.play().reset();

 

        window.setTimeout(() => {

 

            playerVelocity.y = params.jump;

        }, 500)

        // jumpAction.stop();

        window.setTimeout(() => {

 

            jumpAction.crossFadeTo(idleAction, 0.3)

            jumpAction.stop();

            idleAction.play();

        }, 2000)

 

    }

}

 


 

function reset() {

 

    playerVelocity.set(0, 0, 0)

    player.position.set(130, 0, 7500)

    // player.position.set(3, 2, -6)

    // player.position.set(15.75, - 3, 30)

    camera.position.sub(controls.target)

    controls.target.copy(player.position)

    camera.position.add(player.position)

    controls.update()

 

}

 

 

window.addEventListener('resize', () =>

{

    // Update camera

    camera.aspect = window.innerWidth / window.innerHeight

    camera.updateProjectionMatrix()

 

    // Update renderer

    renderer.setSize(window.innerWidth, window.innerHeight)

    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

})


 

 

 

 

 

 

 

/**

* RUN FUNCTION

*/

 

init()

sceneInit()

tick()